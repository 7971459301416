<script>
export default {
  methods: {
    goback() {
      const priv = JSON.parse(sessionStorage.getItem("authUserDetails"));
      const linkName = priv.privileges[0] === 'dashboard' ? 'home' :`All${priv.privileges[0]}`
      this.$router.push({ name: linkName });
    },
  },
};
</script>

<template>
  <div class="my-5 pt-5">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center my-5">
            <h1 class="font-weight-bold text-error">
              3
              <span class="error-text">
                0
                <img
                  src="@/assets/images/error-img.png"
                  alt
                  class="error-img"
                />
              </span>
              4
            </h1>
            <h3 class="text-uppercase">
              Sorry, You're Not Authorized to View This Page
            </h3>
            <div class="mt-5 text-center">
              <a
                class="btn btn-primary waves-effect waves-light"
                @click="goback()"
                >Go Back</a
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
